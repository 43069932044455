jQuery(function($){

  //右クリックを禁止
  document.addEventListener('contextmenu',  () => {
   // event.preventDefault();
  });

  //===============================================
  /* CMS向けにブロック要素に背景画像を設定 */
  //===============================================
  var d = document.getElementsByClassName("js-background");
  for(var i=0; i<d.length; i++){
    var f=d[i].getAttribute("data-src");
    d[i].style.backgroundImage="url('"+f+"')";
  };

  //===============================================
  /* フッターバナーカルーセル */
  //===============================================
  $('#carousel_bnr').slick({
    centerMode: true,
    centerPadding: '10px',
    dots:true,
    arrows: false,
    autoplay: true,
    variableWidth: true,
    speed: 1600,
  });

  //===============================================
  /* pagetopボタン */
  //===============================================
  $('#btn_pagetop').click(function() {
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var speed = 500; // スクロールの速度（ミリ秒）
    $('body,html').animate({scrollTop:position}, speed, 'swing'); // スクロール方式
    return false;
  });

  //===============================================
  /* トップに戻るボタンなどの制御 */
  //===============================================
  var topBtn = $(".js-btn_pagetop");

  //スクロールしてページトップから100に達したらボタンを表示
  $(window).on('load scroll', function(){
    if($(this).scrollTop() > 100) {
      topBtn.addClass('active');
    }else{
      topBtn.removeClass('active');
    }
  });

  /*
  //フッターの手前でボタンを止める
  $(window).on('load scroll', function(){
    var height = $(document).height(), //ドキュメントの高さ 
        position = window.innerHeight + $(window).scrollTop(), //ページトップから現在地までの高さ
        footer = $('#footer').height(); //フッターの高さ
    if ( height - position  < footer ){ 
      topBtn.addClass('absolute');
    } else { 
      topBtn.removeClass('absolute');
    }
  });
  */


  //「#」のみのリンクには反応させない
  $('a[href^="#"]').click(function() {
    return false;
  });

  //===============================================
  /* PCグロナビ展開 */
  //===============================================
  $('#gnav ul li').hover(function(){
    if(!is_tablet()) {
      $(this).children('div').addClass('show');
    }
  },function(){
    if(!is_tablet()) {
      $(this).children('div').removeClass('show');
    }
  });



  //===============================================
  /* ドロワーナビ展開 */
  //===============================================
  $('.js_btn_drawer').on('click',function(){
    if($(this).hasClass('active')) {
      $('#gnav').fadeOut();
      $('body').removeClass('fixed');
      $(this).removeClass('active');
      $('#overlay').removeClass('active');
      document.removeEventListener(
        'touchmove',
        {
          passive: false
        }
      );
    }else{
      $('#gnav').fadeIn();
      $('body').addClass('fixed');
      $(this).addClass('active');
      $(this).removeClass('behind');
      $('.js_btn_search_area').addClass('behind');
      $('#overlay').addClass('active');
      document.addEventListener(
        'touchmove',
        {
          passive: false
        }
      );
    }
  });

  /*
  //オーバーレイのクリック時の処理（ドロワーナビを閉じる）
  $('#overlay').on('click touchstart',function(){
    $('body').removeClass('fixed');
    $('.js_btn_drawer,.js_btn_drawer_sub,.js_btn_search_area').removeClass('active');
    $(this).removeClass('active');
    $('#gnav,#drawer_sub,#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      {
        passive: false
      }
    );
  });
  */

  $('.js-drawer_toggle').on('click', function(){
    if($(this).hasClass('open')) {
      $(this).next('div').slideUp(300);
      $(this).removeClass('open');
    }else{
      $('#gnav ul li div').hide();
      $('.js-drawer_toggle').removeClass('open');
      $(this).next('div').slideDown(200);
      $(this).addClass('open');
    }
  });

  $('.js-ctrl_tgl').on('click',function(){
    if($(this).next('dl').hasClass('show')) {
      $(this).next('dl').removeClass('show');
    }else{
      $(this).next('dl').addClass('show');
    }
  });

  window.addEventListener('resize', resizeWindow);

  $('.question').on('click',function(){
    if($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
    }else{
      $(this).parent().addClass('active');
    }
  });

});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}
function is_tablet(){
  return ($(window).width() < 1100);
}

//===============================================
/* ウィンドウリサイズ時の処理 */
//===============================================
function resizeWindow(event){
  if(is_tablet()) {
    $('#gnav').hide();
    $('#gnav ul li div').hide();
    $('.js-drawer_toggle').removeClass('open');
  }else{
    $('#gnav').show();
    $('#gnav ul li div').show();
  }
  $('.js_btn_drawer').removeClass('active');
  $('#overlay').removeClass('active');
}

//===============================================
/* ページ内リンクスムーススクロール */
//===============================================
const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){
  smoothScrollTrigger[i].addEventListener('click', (e) => {
    e.preventDefault();
    let href = smoothScrollTrigger[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const gap = $('#header').outerHeight();
    const target = rect + offset - gap + 1;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
}